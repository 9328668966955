import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Services
import intl from "../services/intl";

// Utils
import {
  getProductIdForSku,
  getPlanIdForProductSku,
} from "../utils/planToProduct";
import { Font, Color, rem, responsive } from "../utils/style";
import NameMap from "../utils/nameMap";
import metrics from "../utils/metrics";

// Components
import Container from "../components/Container";
import Row from "../components/Row";
import PageSEO from "../components/seo/Page";
import CircleNew from "../components/home/Circle.New";
import ValueProps from "../components/product/ValueProps";
import BarrierBusters from "../components/product/BarrierBusters";
import ProductLandingCards from "../components/product/ProductLandingCards";
import OverlayLink from "../components/OverlayLink";

// Redux
import { connect } from "react-redux";
import appSelectors from "../store/app/selectors";
import bannersSelectors from "../store/banners/selectors";

// Styled Elements
const WhiteWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 500px;

  margin-top: 52px;

  ${responsive.sm`
    margin-top: 60px;
  `}

  ${responsive.md`
    margin-top: 72px;
  `}
`;

const GreyWrapper = styled(WhiteWrapper)`
  background-color: rgba(242, 241, 245, 0.56);
  padding: 80px 0;

  margin-top: 0;

  ${responsive.sm`
    margin-top: 0;
  `}

  ${responsive.md`
    padding: 120px 0;
    margin-top: 0;
  `};
`;

const PageContent = styled.div`
  z-index: 2;
  position: relative;
`;

const ProductContent = styled(PageContent)`
  padding-bottom: 40px;

  ${responsive.md`
    padding-bottom: 80px;
  `};
`;

const PageColumn = styled.div.attrs({
  className: "col",
})`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding: 0;
  `}
`;

const BarrierBustersColumn = styled(PageColumn)`
  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const PageTitle = styled.h1`
  ${Font.circular};
  text-align: center !important;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  margin: 16px 0 24px;

  em {
    ${Font.dutch};
    font-style: italic;
  }

  ${responsive.sm`
    margin-bottom: 32px;
    font-size: ${rem(30)};
    line-height: ${rem(40)};
  `};

  ${responsive.md`
    font-size: ${rem(56)};
    line-height: ${rem(72)};
    letter-spacing: -2px;
    margin-top: 40px;
    margin-bottom: 56px;
  `};
`;

const BodyParagraph = styled.p`
  position: relative;
  ${Font.circular};
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  font-weight: 500;
  margin: 0;
  padding-top: 28px;
  text-align: left;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 4px;
    background-color: ${Color.ritualBlue};
  }

  ${responsive.sm`
    padding-top: 0;
    text-align: center !important;

    &::before {
      display: none;
    }
  `};

  ${responsive.md`
    max-width: 774px;
    margin: 0 auto 16px;
  `};

  ${responsive.lg`
    max-width: 970px;
  `};
`;

const BarrierBustersTitle = styled.h2`
  ${Font.circular};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 500;
  letter-spacing: -0.4px;
  color: ${Color.ritualBlue};
  text-align: center !important;
  margin: 0;

  ${responsive.md`
    font-size: ${rem(66)};
    line-height: ${rem(72)};
    letter-spacing: -2px;
    margin-bottom: 24px;
  `};
`;

export class ShopLandingPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    let {
      slug,
      pageTitle,
      pageDescription,
      socialImage,
      products,
    } = props.data.allContentfulShopLandingPage.edges[0].node;

    // Create productAttributes array
    const productAttributes = products.map((product, index) => {
      const productId = getProductIdForSku(product.sku);
      const planId = getPlanIdForProductSku(product.sku);

      return this.getProductAttributes(product, planId, productId);
    });

    this.state = {
      productAttributes,
      seo: {
        pagePath: `${slug}`,
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
    };
  }

  getProductAttributes(product, planId, productId) {
    let { sku, name, price, slug, socialImage } = product;
    let productAttributes = {
      product_id: productId,
      variant: planId,
      sku: sku,
      name: NameMap(name).plain,
      currency: "USD",
      price: price,
      quantity: 1,
      url: `${process.env.GATSBY_URL}/products/${slug}`,
      image_url: `https:${socialImage.file.url}`,
      nonInteraction: 0,
    };
    return productAttributes;
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Shop Landing",
    });

    if (this.props.bannerType === "BUNDLE") {
      this.props.updateLayoutState({
        banner: true,
      });
    }

    const { productAttributes } = this.state;
    const {
      products,
      productCategory,
      type,
    } = this.props.data.allContentfulShopLandingPage.edges[0].node;

    const productListViewed = products.map((product, index) => {
      return {
        position: index + 1,
        ...productAttributes[index],
      };
    });

    metrics.track("Product List Viewed", {
      category: `${type} for ${productCategory &&
        productCategory.categoryName}`,
      products: productListViewed,
    });
  }

  render() {
    const { isUserDataLoaded } = this.props;
    const {
      header,
      body,
      productLabels,
      products,
      valuePropLabels,
      valuePropIcons,
      barrierBusterTitle,
      barrierBusters,
    } = this.props.data.allContentfulShopLandingPage.edges[0].node;

    const barrierBustersParsed = JSON.parse(
      barrierBusters.childMarkdownRemark.rawMarkdownBody,
    );

    const title = NameMap({
      name: header.childMarkdownRemark.rawMarkdownBody,
    });

    return (
      <>
        <PageSEO {...this.state.seo} />
        {isUserDataLoaded && (
          <OverlayLink
            heading={intl.t(
              "templates.shop.overlay-header",
              "Build Your Bundle",
            )}
            subheading={intl.t(
              "templates.shop.overlay-subheading",
              "Get {amount} off your first bundled order of 2 or more products",
              { amount: intl.formatCurrency(10, { round: true }) },
            )}
            linkDestination="/build-a-bundle"
          />
        )}
        <WhiteWrapper>
          <ProductContent>
            <Container>
              <Row>
                <PageColumn>
                  <PageTitle
                    dangerouslySetInnerHTML={{
                      __html: title.html,
                    }}
                  />
                  <ProductLandingCards
                    productLabels={productLabels}
                    products={products}
                  />
                  <BodyParagraph
                    dangerouslySetInnerHTML={{
                      __html: body.childMarkdownRemark.html
                        .replace("<p>", "")
                        .replace("</p>", ""),
                    }}
                  />
                </PageColumn>
              </Row>
            </Container>
            <ValueProps valueProps={valuePropLabels} icons={valuePropIcons} />
          </ProductContent>
          <CircleNew className="right bottom obscured" />
        </WhiteWrapper>
        <GreyWrapper>
          <PageContent>
            <Container>
              <Row>
                <BarrierBustersColumn className="col-12 col-sm-10 offset-sm-1">
                  <BarrierBustersTitle>
                    {barrierBusterTitle}
                  </BarrierBustersTitle>
                  <BarrierBusters items={barrierBustersParsed} />
                </BarrierBustersColumn>
              </Row>
            </Container>
          </PageContent>
          <CircleNew className="right top lighter" />
        </GreyWrapper>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isUserDataLoaded: appSelectors.isUserDataLoaded(state),
    bannerType: bannersSelectors.bannerType(state),
  };
};

export default connect(mapStateToProps)(ShopLandingPageTemplate);

export const query = graphql`
  query ShopLandingQuery($locale: String!, $slug: String!) {
    allContentfulShopLandingPage(
      filter: { node_locale: { eq: $locale }, slug: { eq: $slug } }
    ) {
      edges {
        node {
          slug
          header {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          productCategory {
            categoryName
          }
          productLabels
          products {
            ...ProductCardFragment
            price
            sku
            stockStatus
            alternateThumbnail {
              fixed(width: 204, height: 204, quality: 90) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
              description
            }
            socialImage {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
                url
              }
            }
          }
          valuePropLabels
          valuePropIcons
          barrierBusterTitle
          barrierBusters {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          pageTitle
          pageDescription
          socialImage {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          type
        }
      }
    }
  }
`;
